header#masthead {

	position: fixed;
	width: 100%;
	background: white;
	top: 0;
	z-index: 2;
	box-shadow: 0 2px 7px 1px rgba(0,0,0,.1);


}

.logged-in header#masthead {
	top: 46px;
}

#page {
	margin-top: 80px;
}

.mm-wrapper_opened #page{
	margin-top: 0px !important;
}

@media (--tablet-up) {
	.logged-in header#masthead {
		top: 32px;
	}

	#page {
		margin-top: 87px;
	}


	.mm-wrapper_opened #page{
		margin-top: 0px !important;
	}
}

#header-wrapper {
	background: #fff;
}

.site-branding {
	padding: 0.75rem 0 0 .75rem;
}

@media (--sm-desktop-up){
	.site-branding {
		padding: 0.75rem 0 0 2rem;
	}
}

.main-navigation,
.site-branding {
	float: left;
}

.main-navigation {
	width: 35%;
}

.site-branding {
	width: 65%;
}

@media (--desktop-up) {
	.main-navigation {
		width: 73%;
	}

	.site-branding {
		width: 27%;
	}
}


.site-header {
	display: flex;
}

.site-title{
	margin: 0;
}

/* Navigation */

.dropdown-symbol {
	display: none !important;
}

.primary-menu-container a {
	color: var(--color-theme-primary);
	text-decoration: none;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: var(--font-weight-medium);
}

.primary-menu-container a:hover {
	color: var(--color-theme-secondary);
	text-decoration: none;
}

.header-phone {
	display: none;
}

@media (--desktop-up) {

	.header-phone {
		display: block;
		float: right;
		position: relative;
		margin-top: 8px;
	}

	.header-phone a {

		padding: 0 0.5em 0 2em;
		color: var(--color-theme-secondary);
		font-weight: var(--font-weight-bold);
		margin-top: 12px;
		font-size: 24px;

	}

	.header-phone a::before {
		content: 'Call Now';
		font-family: var(--global-font-family);
		color: var(--color-theme-secondary);
		position: absolute;
		top: -10px;
		font-size: 14px;
		font-weight: var(--font-weight-medium);
		text-transform: uppercase;

	}

	.header-phone a:hover {
		color: var(--color-theme-secondary);
		text-decoration: none;
	}

	.main-navigation ul li a {

		padding-top: 0.7em;
	}

}

ul.sub-menu {
	padding-top: 16px;
}


/* Mmenu override */
.mm-listitem__text {
	flex-basis: auto;
	font-weight: var(--font-weight-bold);
	color: var(--color-theme-white) !important;
}


.mm-navbars_bottom .mm-navbar {
	background: var(--color-theme-secondary);
}

.mm-navbar > * {

	color: var(--color-theme-white) !important;
	font-weight: var(--font-weight-bold);

}


.mm-menu_offcanvas.mm-menu_opened {
	z-index: 100;
}

/* Navigation icon */

a.menu-phone {
	background: var(--color-theme-secondary);
}

#mm-1 .mm-listview .mm-listitem:last-child {
	background-color: var(--color-theme-secondary);
	text-align: center;
	font-size: 24px;
	padding: 0.5em;
	position: relative;
}


@media (--tablet-down) {
	#mm-1.mm-listview .mm-listitem:last-child {
		background-color: var(--color-theme-secondary);
		text-align: center;
	}
}

@media (--tablet-up) {

	.mobile-phone {
		display: none;
	}

}


a.mm-navbar__title {
	opacity: 0.3;
}

button.menu-toggle {
	padding: 25px !important;
	background-color: var(--color-theme-secondary) !important;
}

.menu-toggle-bar-wrap {
	display: inline-block;
	width: 35px;
	height: 25px;
	cursor: pointer;
	position: absolute;
	left: 8px;
	top: 13px;
}

.menu_toggle_bar {
	height: 4px;
	background-color: var(--color-theme-white);
	position: absolute;
	left: 0;
	width: 100%;
	transform: rotate(0deg);
	transition: all 0.5s ease;
}

.top_bar {
	top: 0;
}

.mid_bar {
	top: 10px;
}

.bottom_bar {
	bottom: 0;
}

a.mm-listitem__text {
	min-width: 210px;
}
