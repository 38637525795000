@import "_custom-properties.css";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
/* stylelint-disable */
body,
button,
input,
select,
optgroup,
textarea {
	color: var(--global-font-color);
	font-family: var(--global-font-family);
	font-size: var(--font-size-regular);
	line-height: var(--global-font-line-height);
	font-weight: var(--font-weight-light);
}

@media (--desktop-down) {


body,
button,
input,
select,
optgroup,
textarea {
	font-weight: var(--font-weight-regular);
}

}

.lead {

	font-size: 20px;
	line-height: 34px;
}

/* stylelint-enable */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--highlight-font-family);
	clear: both;
	letter-spacing: normal;
}

h1, h2, h3, h4 {
	/* text-transform: uppercase; */
}

h1, h2, h3 {
	color: var(--color-theme-primary);
}

h4 {
	color: var(--color-theme-secondary);
	font-family: var(--global-font-family);

	font-size: 24px;
	line-height: 34px;
}

h1 {
	font-size: 54px;
	line-height: 64px;
}

h2 {
	font-size: 44px;
	line-height: 54px;
}

h3 {
	font-size: 34px;
	line-height: 44px;
}


.entry-header h1.entry-title,
.page-header .page-title {
	font-size: 2.5rem;
	line-height: 1.4;
	margin-top: 1em;
	margin-bottom: 1em;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote,
q {
	quotes: "" "";
}

blockquote {
	border-left: 4px solid var(--color-quote-border);
	margin: 0;
}

.entry-content > blockquote {
	padding-left: 1em;
	margin-left: 1.5rem;
}

@media (--content-query) {

	.entry-content > blockquote {
		margin-left: auto;
	}
}

blockquote cite {
	color: var(--color-quote-citation);
	font-size: 13px;
	margin-top: 1em;
	position: relative;
	font-style: normal;
}

address {
	margin: 0 0 1.5em;
}

/* stylelint-disable */
pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 1.5em;
	max-width: 100%;
	overflow: auto;
	padding: 1.5em;
}
/* stylelint-enable */

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 0.8rem;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}

@media (--sm-desktop-up) {

	span.blue-line::after {
		content: "";
		width: 108%;
		position: absolute;
		background: rgba(50, 182, 227, 0.2);
		height: 20px;
		bottom: 2px;
		left: -4%;
		z-index: -1;
	}

}

.small-text {
	font-weight: var(--font-weight-medium);
	text-transform: uppercase;
	font-family: var(--global-font-family);
	margin: 0;
}

.large-text {
	text-transform: uppercase;
	font-family: var(--highlight-font-family);
	margin: 0;

}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */

   span.blue-line::after {
	   display: none;
   }
}
