/**
 * Custom Properties
 * aka CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

:root {
	--global-font-color: #0F5C76;
	--global-font-family: "Montserrat", serif;
	--global-font-size: 16;
	--global-font-line-height: 2rem;
	--highlight-font-family: "Fjalla One", "Helvetica Neue", "Arial Narrow", sans-serif;
	/* --content-width: 45rem; */
	/* --dropdown-symbol-width: 0.7em; */

	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 600;

	--color-theme-light: #ffffff;
	--color-theme-medium: #f2f2f2;
	--color-theme-dark: #003e53;

	/* Custom editor colors */
	--color-theme-primary: #0F5C76;
	--color-theme-secondary: #FA9611;

	--color-theme-accent: #32B6E3;
	/* --color-theme-red: #c0392b; */
	/* --color-theme-green: #8accc1;
	--color-theme-blue: #003e53; */

	/* --color-theme-yellow: #f1c40f; */
	--color-theme-black: #333333;
	--color-theme-grey: #e6e6e6;
	--color-theme-white: #ffffff;

	/* --color-custom-daylight: #97c0b7;
	--color-custom-sun: #eee9d1; */

	--color-link: #FA9611;
	--color-link-visited: #32B6E3;
	--color-link-active: #32B6E3;

	/* --color-quote-border: #000;
	--color-quote-citation: #6c7781; */

	/* Custom editor font sizes */
	/* --font-size-small: calc(16 / var(--global-font-size) * 1rem);
	--font-size-regular: calc(var(--global-font-size) / 16 * 1rem);
	--font-size-large: calc(36 / var(--global-font-size) * 1rem);
	--font-size-larger: calc(48 / var(--global-font-size) * 1rem); */
}
