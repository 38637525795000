@import "_custom-properties.css";

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
/* stylelint-disable */
a {
	color: var(--color-link);
	transition: 0.3s ease-in-out;
}
/* stylelint-enable */

a:visited {
	color: var(--color-link-visited);
}

a:hover,
a:focus,
a:active {
	color: var(--color-link-active);
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}

/* Buttons */

/* Gravity Form Buttons */

.gfield_checkbox input {
    margin-top: 0px !important;
}

input.gform_button {
    background-color: transparent !important;
    border: 0 !important;
    font-family: var(--highlight-font-family) !important;
    text-transform: uppercase !important;
    color: white !important;
    font-size: 24px !important;
    text-align: center !important;
    margin: 0px !important;
	min-height: 0px !important;
	padding: 0 !important;
}

.gform_footer {
	background: var(--color-theme-secondary) !important;
	font-family: var(--highlight-font-family) !important;
	text-transform: uppercase !important;
	font-size: 24px !important;
	padding: .5em 2.5em !important;
	color: var(--color-theme-white) !important;
	overflow: hidden !important;
	position: relative !important;
	z-index: 1 !important;
	border-radius: 0px !important;
	border: 0px !important;
	transition: ease-in-out 0.3s !important;
	width: fit-content !important;

}

.gform_footer::before {
	content: "" !important;
	position: absolute !important;
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
	z-index: -2 !important;
	background: var(--color-theme-secondary) !important;
}

.gform_footer::after {
	content: "" !important;
	position: absolute !important;
	z-index: -1 !important;
	transition: all 0.3s !important;
	width: 100% !important;
	height: 0 !important;
	top: 50% !important;
	left: 50% !important;
	opacity: 0 !important;
	transform: translateX(-50%) translateY(-50%) rotate(45deg) !important;
	background: #bb710d !important;
}

.gform_footer:hover::after {
	height: 300% !important;
	opacity: 1 !important;
}

.gform_footer::before {
	background: var(--color-theme-secondary) !important;
}

.gform_footer::after {
	background: #bb710d !important;
}

.gform_footer:hover,
.gform_footer:visited {
	color: var(--color-theme-white) !important;
}

/* Global buttons */

.btn {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	line-height: 1.5;
	transition: ease-in-out 0.3s;
}

.btn-link {
	font-family: var(--global-font-family);
	text-transform: uppercase;
	font-weight: 700;
	position: relative;

}

.btn-link::after {
	content: url('http://brighthouse.local/wp-content/uploads/2020/02/arrow.svg');
	position: absolute;
	right: -40px;
}

.btn-link:active,
.btn-link:visited {
	color: var(--color-theme-secondary);
}

.btn-primary {
	font-family: var(--highlight-font-family);
	text-transform: uppercase;
	font-size: 24px;
	padding: 0.5em 1.25em;
	color: var(--color-theme-white);
	background: 0 0;
	overflow: hidden;
	position: relative;
	z-index: 0;
}

.btn-primary::before{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -2;
}

.btn-primary::after {
	content: "";
	position: absolute;
	z-index: -1;
	transition: all 0.3s;
	width: 100%;
	height: 0;
	top: 50%;
	left: 50%;
	opacity: 0;
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.btn-primary:hover::after{
	height: 300%;
	opacity: 1;
}

.blue::before {
	background: var(--color-theme-primary);
}

.blue::after {
	background: var(--color-theme-accent);
}

.blue:hover {
	color: #fff;
	text-decoration: none;
}

.orange::before {
	background: var(--color-theme-secondary);
}

.orange::after {
	background: #bb710d;
}

.blue:hover,
.blue:visited,
.orange:hover,
.orange:visited{
	color: var(--color-theme-white);
}

@media (--desktop-up) {
	.btn-primary,
	.gform_footer {
		padding: 0.5em 2.5em;
	}
}
