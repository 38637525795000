/**
 * Custom styles for the front-page.php template.
 */

/* General Settings */


a {
	transition: 0.3s ease-in-out;
	cursor: pointer;
}

a:active {
	color: #FA9611;
}

a:visited {
	color: #0F5C76;
}



.wrapper {
	float: left;
	clear: both;
	width: 100%;
}

.desktop {
	display: none;
}

@media (--sm-desktop-up) {

	.desktop {
		display: block;
	}
}

/* Process */

.process {
	padding: 50px 0;
}

/* Icon Feature Section */


.icon-feature {
	padding: 75px 0 0 0;
	/* background: url(/wp-content/uploads/2020/02/grunge.png); */
	background-repeat: no-repeat;
	background-size: auto;
	background-position: right center;
	margin-bottom: -100px;

}

@media (--tablet-down) {
	.icon-feature {
		background: none;
	}
}

/*@media (--desktop-up) {*/
/*	.icon-feature {*/
/*		padding-top: 50px;*/
/*	}*/
/*}*/

.fifty_fifty_front_page {
	padding-top: 100px;
	margin-bottom: 0;
}

.icon-intro-content {
	margin-bottom: 75px;
}

.icon-intro-content h1 {
	margin: 0 auto;
	position: relative;
}


@media (--desktop-down) {
	.fifty_fifty_front_page .fifty_fifty_text {
		padding-bottom: 0;
	}

	/* p.lead {
		font-size: 16px;
		line-height: var(--global-font-line-height);
	} */

	/* .icon-wrap {
		margin-bottom: 20px;
	} */
}

@media (--sm-desktop-up) {

	.icon-intro-content h1 {
		margin: 0 auto;
		position: relative;
		width: fit-content;
	}

}

@media (--desktop-up) {

	/* .icon-feature {
		padding: 125px 0 0 0;
	} */

	.icon-intro-content {
		text-align: center;
		width: 80%;
		margin: 0 auto 75px auto;
	}

	/* .icon-wrap {
		transition: 0.3s ease-in-out;
	}

	.icon-wrap:hover {
		transform: scale(1.1);
	} */

}

/* Fullwidth Image */
.fullwidth-image {
	background-repeat: no-repeat;
	background-size: cover;
	padding: 24em;
	background-position: top center;
}

@media (--desktop-down) {

	.fullwidth-image {
		padding: 16em 0;
	}

}
