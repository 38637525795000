#pagebuilder-wrapper {

	/* background: url(/wp-content/uploads/2020/02/grunge.png); */
	background-repeat: no-repeat;
	background-position: top right;
	margin-top: 50px;

}

@media (--tablet-down) {
	#pagebuilder-wrapper {
		background: none;
	}
}

@media (--sm-desktop-up) {

	#pagebuilder-wrapper {
		margin-top: 75px;

	}
}

.wrapper,
.module {
	width: 100%;
	clear: both;
}

/* contact page */
.location-title .entry-title {
	padding-top:50px;
}
.contact-location-wrap h2 a {
	text-decoration: none;
}

/* .module {
	margin-top: 50px;
} */

/* .small-text {
	font-weight: var(--font-weight-light);
} */

/* Call to Action */

.cta {
	/* background: url(/wp-content/uploads/2020/02/grunge.png); */
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right center;
}

.cta-container {
	text-align: center;
	background: #0F5C76;
	padding: 75px;
	margin: 75px auto;
}

.cta-text-wrap h4 {
	margin: 0;
	text-transform: uppercase;
}

.cta-text-wrap h2 {
	color: #ffffff;
	margin: 10px 0 20px 0;
	text-transform: uppercase;

}

/* Process Module */

.process {
	/* background: url(/wp-content/uploads/2020/02/grunge.png); */
	background-repeat: no-repeat;
	background-size: auto;
	background-position: top left;
	padding: 50px 0;
	position: relative;
	z-index: 0;
}

@media (--sm-desktop-up) {
	.process {
		/* padding: 25px 0; */
	}
}

.process-toggle a {
	text-decoration: none;
}
.step-cta-text .small-text {
	text-transform: uppercase;
	font-size: 24px;
	font-weight: 600;
	font-family: "Montserrat", serif;
	margin: 0;
}

.step-cta-text .large-text {
	font-family: "Fjalla One", "Helvetica Neue", "Arial Narrow", sans-serif;
	text-transform: uppercase;
	font-size: 46px;
	margin: 10px auto 20px auto;
	line-height: 56px;
}

.process-container {
	/* margin-bottom: 50px; */
	padding-bottom: 50px;
}

.process-container h2 {
	margin: 0;
	position: relative;
	width: fit-content;
}

.process-toggle {
	margin-bottom: 20px;
	margin-top: 20px;
}

.process-toggle,
.process-toggle span {
	font-size: 18px;
	text-transform: uppercase;
	color: #0F5C76;
	display: inline-block;
	position: relative;
	font-weight: 500;
}


.divider {
	content: "";
	position: absolute;
	width: 2px;
	height: 30px;
	background: #e6e6e6;
	margin: 0 6px;
	top: 10px;

}

@media (--tablet-down) {
	.process-container h2 {
		text-align: center;
		width: auto;
		margin-bottom: 10px;
	}
	.process-toggle {
		width: 100%;
	}

	.divider {
		width: 3px;
		float: left;
		height: 50px;
		top: 0;
	}


	.process-toggle .waste-removal-hl,
	.process-toggle .power-washing-hl {
		width: 45%;
		float: left;
		text-align: center;
		line-height: 24px;
	}

}

@media (--sm-desktop-up) {

	.process-toggle,
	.process-toggle span {
		font-size: 24px;
	}

	.divider {
		margin: 0 30px;
		width: 3px;
		top: 16px;
		height: 50px;

	}

}

.steps-container {
	width: 100%;
}

.step-wrap-column {
	width: 100%;
	float: left;
	position: relative;
	transition: 0.3s ease-in-out;
}

.step-wrap-column:hover {
	transform: scale(1.1);
}

.step-wrap {
	border-bottom: 4px solid #32B6E3;
	background-color: #ffffff;
	background: #ffffff;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
	margin: 50px 30px 30px 0;
	padding: 40px;

}

.step-cta-wrap .btn {
	box-shadow: 1px 0 6px rgba(0,0,0,.3);
}

.step-cta-text {
	width: 100%;
	float: left;
	padding: 50px;
	text-align: center;
	overflow: hidden;
}

@media (--desktop-down) {
	.waste-removal-steps .step-cta-text,
	.power-washing-steps .step-cta-text {
		margin-bottom: 50px;
	}
}

.waste-removal-steps .step-cta-text {
	background: #FA9611;
}

.power-washing-steps .step-cta-text {
	background: #0F5C76;
}


.waste-removal-steps .small-text {
	color: #0F5C76;
}

.power-washing-steps .large-text,
.waste-removal-steps .large-text {
	color: #ffffff;
}

.power-washing-steps .small-text {
	color: #FA9611;
}


.sn {

	color: #FA9611;
	font-size: 75px;
	font-weight: 600;
	position: absolute;
	left: 0;
	top: 35px;
}

@media (--sm-desktop-down) {

	.step-wrap {
		margin-right: 0;
	}

	.step-cta-text {
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
	}

	.step-cta-wrap {
		margin-right: 0;
	}

}

@media (--tablet-up) {
	.step-wrap {
		height: 315px;
	}
}
@media (--sm-desktop-up) {

	.step-wrap-column {
		width: 50%;
		float: left;
		position: relative;
	}

	.waste-removal-steps,
	.power-washing-steps {
		display: flex;
	}

	.steps-container {
		width: 70%;
	}

	.step-cta-text {
		padding: 0;
		text-align: left;
	}

	.step-cta-wrap {
		width: 30%;
		display: flex;
		align-items: center;
		padding: 0 30px;
		margin: 50px 0 30px 0;
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
	}

	.step-wrap {
		border-bottom: 4px solid #32B6E3;
		background: #ffffff;
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
		margin: 50px 30px 30px 0;
		padding: 40px;
		height: 340px;
	}

	.waste-removal-steps .step-cta-wrap {
		background: #FA9611;
	}

	.power-washing-steps .step-cta-wrap {
		background: #0F5C76;
	}
}
@media (--tablet-up) {
	.step-wrap-column {
		width: 50%;
		padding-right: 15px;
	}
}
@media (--desktop-up) {

	.step-wrap-column {
		width: 50%;
		float: left;
		position: relative;
	}

	.step-cta-wrap {
		width: 30%;
}

.step-wrap {
	height: 310px;

}


	.step-cta-text .btn-primary {
		padding: 0.5em 2.5em;
	}

}

/* Icon Feature Module */

.icon-feature-row .col-lg-4 {
	display: flex;
}
.icon-intro-content {
	margin-bottom: 75px;
}

.icon-intro-content h1 {
	margin: 0 auto;
	position: relative;
	width: fit-content;
}

.icon-wrap {
	text-align: center;
	padding: 30px;
	border-bottom: 4px solid #32B6E3;
	transition: 0.3s ease-in-out;
	background-color: #ffffff;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);


}

.icon {
	margin: auto;
	height: 100px;
}

@media (--desktop-down) {

	/* .icon-intro-content h1 {
		font-size: 30px;
	} */

	/* p.lead {
		font-size: 16px;
		line-height: var(--global-font-line-height);
	} */

	.icon-wrap {
		margin-bottom: 20px;
	}
}

@media (--desktop-up) {


	.icon-intro-content {
		text-align: center;
		width: 80%;
		margin: 0 auto 75px auto;
	}

	.icon-wrap {
		transition: 0.3s ease-in-out;
	}

	.icon-wrap:hover {
		transform: scale(1.1);
	}

}

/* Fifty Fifty Wrapper */


.fifty_fifty_wrapper {
	margin: 30px auto;
}

.fifty_fifty_wrapper {
	padding-top: 30px;
	padding-bottom:30px;
}

@media (--sm-desktop-up) {
	.fifty_fifty_wrapper {
		margin: 50px auto;
		padding-top: 50px;
		padding-bottom:50px;
	}
}

.fifty_fifty_image {
	background-size: cover;
	background-repeat: no-repeat;
	padding: 12rem 0;
}


.fifty_fifty_text {
	padding: 4rem 0 4rem 4rem;
}

.fifty_fifty_text h2 {
	margin: 0;
	position: relative;
}
@media (--sm-desktop-up) {
	.fifty_fifty_text h2 {
		width: fit-content;
	}
}

@media (--sm-desktop-down) {

	.fifty_fifty_text {
		padding: 4rem 1rem;
	}

}

/*List Item Module */

.list-intro-wrap {
	text-align: center;
	margin-bottom: 30px;
}

.list-wrap {
	margin-left: 25px;
}

.list-item {
	margin-right: 20px;
}

.list-container {
	/* padding: 3rem 1rem; */
	margin: 30px auto;
}

@media (--sm-desktop-up) {
	.list-container {
		margin: 50px auto;
	}
}

h2.list-headline {
	position: relative;
	width: fit-content;
	margin: auto;
}

.checkmark::before {
	content: url('/wp-content/uploads/2020/02/check.svg');
	position: absolute;
	left: -25px;
}

.x::before {
	content: url('/wp-content/uploads/2020/02/x.svg');
	position: absolute;
	left: -25px;
	top: 20px;
}

.bullet::before {
	content: "•";
	position: absolute;
	left: -25px;
	top: 12px;
	font-size: 45px;
	color: #FA9611;
}

/* Testimonial */

.testimonial {

	padding: 0;

}


.testimonial-left-box {

	display: none;
	background-color: #0F5C76;

}


.testimonial-left-box h4 {
	margin: 0;
	text-transform: uppercase;
}

.testimonial-left-box h2 {
	color: #fff;
	text-transform: uppercase;
	margin: 10px 0 20px;
}

.testimonial-right-box {
	width: 100%;
	float: left;
	background: #fff;
	display: flex;
	align-items: center;
}

.testimonial-text-wrap,
.testimonial-left-wrap {
	padding: 50px;
	width: 100%;
}

.testimonial-name::before {
	content: "-";
	margin-right: 20px;
}

.testimonial-name {
	text-transform: uppercase;
	text-align: right;
	font-weight: 500;
}

@media (--sm-desktop-up) {

	.testimonial-wrap {
		display: flex;
	}

	.testimonial {
		padding: 100px 0;
		background-image: url(/wp-content/uploads/2020/02/waterdroplets-1.jpg);
		background-size: cover;
		background-repeat: no-repeat;
	}

	.testimonial-right-box {
		width: 57.334%;
	}

	.testimonial-left-box {
		width: 42.666%;
		display: flex;
		float: left;
		align-items: center;
	}

}

@media (--desktop-up) {

	.testimonial {
		padding: 200px 0;
	}

}

/* Contact Form Module */

.page-template-template-contact #pagebuilder-wrapper {
	margin-top: 0;
}

.contact_wrapper {
	padding: 50px 15px;
	position: relative;
}

.contact-location-wrap {
	margin-top:50px;
}


.jumper {
	position: absolute;
	top: -150px;
}

.contact_text_wrap {
	text-align: center;
	margin-bottom: 50px;
}

@media (--tablet-up) {

	.contact_wrapper {
		padding: 75px 0;
	}

}


/* Full Width Module */
.full_width_container {
	margin: 30px auto;
}

.image_content_width img {
	margin: auto;
}
@media (--sm-desktop-up) {


	.full_width_container {
		margin: 50px auto;
	}
}

@media (--desktop-up) {



	.full_width_wrapper .container {
		max-width: 800px;

	}


}

/* Image Module */

.image_full_width img {
	object-fit: cover;
	width: 100%;
	height: 500px;
	object-position: center center;
}


.image_content_width {
	text-align: center;
	float: none;
	object-fit: cover;
	width: 100%;
	object-position: center center;

}

/*  Accordion Module */
.accordion_wrapper {
	padding: 3rem 0;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-radius: 0;
  margin: 5px 0;
  font-size: 1rem;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}



/* Columns Module */

.columns_wrapper .column-wrap img {
	/* object-fit: cover;
	height: 300px;
	width: 100%;
	object-position: center center; */
}
@media (--tablet-up) {

	.columns_wrapper .col-md-6 img {
		/* object-fit: cover;
		width: 100%;
		height: 300px;
		object-position: center center; */
	}

	.columns_wrapper .col-md-4 img{
		/* object-fit: cover;
		width: 100%;
		height: 225px;
		object-position: center center; */
	}

	.columns_wrapper .col-md-3 img{
		/* object-fit: cover;
		width: 100%;
		height: 175px;
		object-position: center center;
	} */

}
}




.columns_wrapper h3 {
	color: #0F5C76;
}

.columns_wrapper {

}

.columns_wrapper img {

}

.col-txt-wrap {
	padding: 2rem 0;
}

.col-txt-wrap h3 {
	font-size: 1.875rem;
	/* text-transform: uppercase; */
}

@media (--desktop-up) {

	.col-txt-wrap {
		padding: 2rem 1rem;
	}


}
@media (--desktop-up) {



}


/* slider */
/* .thumbnail img {
   box-sizing: border-box;
   border: 1px solid #ddd;
   padding: 2px;
   margin: 0 1% 15px 0;
   width: 32.6667%;
   display: inline-block;
}

.thumbnail img:nth-of-type(3n+3) {
   margin-right: 0;
} */

/* Contact Page */
.contact-location-wrap p {
	margin: 0;
}
