.error404 .site {
	background-color: var(--color-theme-primary);
}

.error-text-wrap {

	color: white;
	text-align: center;
	padding: 50px 15px;
}

.error-text-wrap h1, .error-text-wrap h4 {
	color: white;
}

.error-text-wrap h4 {
	font-weight: var(--font-weight-regular);
}

.error-image {
	margin-top:50px;
	margin-bottom: 50px;
}
