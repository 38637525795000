.hero {
	position: relative;
}

.interior-hero {
	padding: 6rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


.hero-cs-default::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(51, 51, 51, 0.3);
	z-index: 0;
}

.hero-cs-blue::after {
	/* content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(15, 92, 118, 0.8);
	z-index: 0; */
}

.hero-cs-orange::after {
	/* content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(250, 150, 17, 0.8);
	z-index: 0; */
}

.hero-block {
	position: relative;
	display: block;
	padding: 3rem 2rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
}

.hero-block.homepage-hero-top {
	width: 100%;
	position: relative;
}

.hero-block.homepage-hero-top:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(25, 188, 233, 0.70);
}

@media (--tablet-up) {
	.hero-block {
		display: flex;
		align-items: center;
		padding: 4rem 2rem;
		width: 50%;

	}

}
@media (--sm-desktop-up) {
	.hero-block {
		padding: 4rem;
	}
}
@media (--md-desktop-up) {
	.hero-block {
	}
}

@media (--desktop-up) {
	.hero-block {
		padding: 9rem 4rem;
	}
}

@media (--lg-desktop-up) {
	.hero-block {
		padding: 9rem 7rem;
	}


}

@media (--sm-desktop-up) {



}

.homepage-hero-left-half::after,
.homepage-hero-right-half::after {
/*
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 0; */

}

.homepage-hero-left-half::after {

	/* background-color: rgba(250, 150, 17, 0.8); */

}

.homepage-hero-right-half::after {

	/* background-color: rgba(15, 92, 118, 0.9); */

}

.hero-content-wrap {
	text-align: center;
	z-index: 1;
	position: relative;
}



@media (--tablet-up) {
	.interior-hero {
		min-height: 300px;
	}
}
@media (--desktop-up) {
	.interior-hero .container {
		max-width: 1500px !important;
	}

	.interior-hero {
		min-height: 350px;
	}
}

.hero-content-wrap .btn {
	margin-top: 0.5rem;
	box-shadow: 1px 0 6px rgba(0,0,0,.3);
}

.hero-content-wrap .small-text {
	margin: 0;
	font-size: 24px;
	line-height: 28px;
}

.hero-content-wrap .large-text {
	color: var(--color-theme-white);
	font-size: 60px;
	line-height: 64px;
	margin: 10px 0;
	text-shadow: 1px 0px 6px rgba(0, 0, 0, 0.3);

}

@media (--lg-desktop-up) {

	.hero-content-wrap .small-text {
		font-size: 32px;
	}


	.hero-content-wrap .large-text {
		font-size: 80px;
		line-height: 88px;
	}
}




/* @media (--sm-desktop-up) {

	.hero-content-wrap .small-text {
		font-size: 2.125rem;
		line-height: 3.125rem;
	}

	.hero-content-wrap .large-text {
		font-size: 4.375rem;
		line-height: 5.375rem;
	}

} */

.hero-cs-blue .small-text,
.hero-cs-default .small-text {
	color: var(--color-theme-secondary);
}

.hero-cs-orange .small-text {
	color: var(--color-theme-primary);
}


/* Homepage Hero Section */
.homepage-hero {
	width: 100%;
	float: left;
	display: flex;
	flex-wrap: wrap;
}

.homepage-hero .small-text {
	font-size: 24px;
}

.homepage-hero .large-text {
	font-size: 60px;
}



.homepage-hero-left-half .small-text {
	color: var(--color-theme-primary);
}

.homepage-hero-right-half .small-text {
	color: var(--color-theme-secondary);
}

@media (--lg-desktop-up) {
	.homepage-hero .large-text {
		font-size: 80px;
		line-height: 88px;
	}

	.homepage-hero .small-text {
		font-size: 32px;
		margin-bottom: 15px;
	}


	.homepage-hero .hero-content-wrap {
			width: 620px;
			margin: auto;
		}


}
/* Location Hero Section */

.location-title h1 {
	text-align: center;
}
